.ant-popover-inner {
  background-color: #141414 !important;
}

.ant-popover-title{
    color: white !important;

}
.ant-popover-arrow::before{
background: #141414 !important;
}